import MobileSelect from 'mobile-select';
import React, { useRef } from 'react';
import areaData from '../m_city_picker/area.json';

export default function CityRetreat({ onChange, select, p, c }) {
  const retreatRef = useRef();

  function handleRef(v) {
    if (retreatRef.current) return;
    let data = areaData.map((item) => ({
      label: item.label,
      children: item.children.map((i) => ({ value: i.value, label: i.label })),
    }));
    retreatRef.current = new MobileSelect({
      trigger: '#m-city-retreat',
      title: '地址选择',
      wheels: [{ data }],
      keyMap: { id: 'value', value: 'label', childs: 'children' },
      onChange: (cur, idx) => {
        onChange(cur.map((i) => i.label));
      },
      initValue: p + c,
    });
  }
  return (
    <div id="m-city-retreat" className={`m_city_retreat city ${select ? '' : 'placeholder'}`} ref={(v) => handleRef(v)}>
      <span>请选择</span>
    </div>
  );
}
