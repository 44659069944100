import React, { useEffect, useState } from 'react';
import './index.scss';
import { getQueryOrder } from '../../api';
import { showToast, parseLocationParamsAll } from '../../utils';
import PopupDialog from '../../components/popup_dialog/index';

export default function SearchOrderPage() {
  // 电话
  const [phone, setPhone] = useState('');
  // url中code
  const [code, setCode] = useState('');
  const [orderList, setOrderList] = useState([]);
  const [record, setRecord] = useState({});
  const [popupDialogShow, setPopupDialogShow] = useState(false);
  const [errorTxt, setErrorTxt] = useState('请按手机号搜索订单');
  const [currentPage, setCurrentPage] = useState();
  // 当前分页
  const [pageNum, setPageNum] = useState(1);
  const [pageSize] = useState(10);
  const [total, setTotal] = useState();

  useEffect(() => {
    let code = parseLocationParamsAll(window.location.search)?.code;
    if (code) {
      setCode(code);
    }
  }, []);
  // 查询订单列表
  async function searchOrderList(page) {
    if (!phone || /^1[3456789]\d{9}$/.test(phone) !== true) {
      return setErrorTxt('请输入正确的手机号');
    }
    try {
      let res = await getQueryOrder({
        receiverPhone: phone,
        pageId: code,
        pageSize,
        pageNum: page,
      });
      if (res.code === 200) {
        if (res.total === 0) {
          setOrderList([]);
          setErrorTxt('没有该手机号相关订单');
        } else {
          setCurrentPage(Math.ceil(res.total / pageSize) ?? 1);
          setOrderList(res.rows);
          setTotal(res.total);
        }
      } else {
        // showToast(res.msg ?? '发送失败');
        setOrderList([]);
        setErrorTxt(res.msg ?? '查询失败');
      }
    } catch (e) {
      showToast(e.message ?? '发送失败');
    }
  }
  function handChangePageSize(type) {
    let page = pageNum;
    if (currentPage === pageNum && type === 'add') {
      showToast('已经最后一页');
      return;
    }
    if (pageNum === 1 && type === 'minus') {
      showToast('已经是第一页');
      return;
    }
    if (type === 'add') {
      ++page;
    } else {
      --page;
    }
    console.log('page', page);
    setPageNum(page);
    searchOrderList(page);
  }
  return (
    <div className="order-page">
      {/* 订单查询输入框 */}
      <div className="order-search-top">
        <div className="title">订单进度查询</div>
        <div className="search-input">
          <img src="/pic/icon-phone.jpg" mode="scaleToFill" />
          <input
            type="number"
            value={phone}
            onChange={(e) => {
              e.currentTarget.value.length < 12 && setPhone(e.currentTarget.value);
            }}
            placeholder="请输入手机号查询订单"
          />
          <span className="search-input-number">{phone.length}/11</span>
        </div>
        <div className="search-btn" onClick={() => searchOrderList(pageNum)}>
          查询订单
        </div>
      </div>
      {orderList && orderList.length > 0 && (
        <div className="inner-box">
          <div className="inner-items">
            <div className="tips">共查询到{total}个订单</div>
            {/* 订单信息盒子 */}
            {orderList.map((item, index) => (
              <div key={index} className="order-info">
                <div className="order-item">
                  <div className="item-label">订单状态：</div>
                  <div className="item-value">{item.openStatus}</div>
                </div>
                <div className="order-item border-bottom">
                  <div className="item-label">订单编号：</div>
                  <div className="item-value">{item.orderSn}</div>
                </div>
                <div className="order-item">
                  <div className="item-label">套餐名称：</div>
                  <div className="item-value">{item.pageName}</div>
                </div>
                <div className="order-item">
                  <div className="item-label">收货人：</div>
                  <div className="item-value">{item.receiverName}</div>
                </div>
                <div className="order-item">
                  <div className="item-label">联系电话：</div>
                  <div className="item-value">{item.receiverPhone}</div>
                </div>
                <div className="order-item">
                  <div className="item-label">物流公司：</div>
                  <div className="item-value">{item.expressName}</div>
                </div>
                <div className="order-item">
                  <div className="item-label">物流订单号：</div>
                  <div className="item-value">
                    {item.expressNum}
                    {item.expressNum && (
                      <span
                        onClick={() => {
                          setRecord(item);
                          setPopupDialogShow(true);
                        }}
                      >
                        查看物流
                      </span>
                    )}
                  </div>
                </div>
                <div className="order-item">
                  <div className="item-label">下单时间：</div>
                  <div className="item-value">{item.createTime}</div>
                </div>
              </div>
            ))}
          </div>

          <div className="pt-20 pb-20 page-paging">
            <img
              className={`mr-20 ${pageNum === 1 && 'disable'}`}
              onClick={() => handChangePageSize('minus')}
              src="/pic/icon-left.png"
              alt=""
            />
            <div className="current-page">{pageNum}</div>
            <img
              className={`ml-20 ${currentPage === pageNum && 'disable'}`}
              onClick={() => handChangePageSize('add')}
              src="/pic/icon-right.png"
              alt=""
            />
          </div>
        </div>
      )}
      {/* 空数据展示的信息 */}
      {orderList.length == 0 && (
        <div className="order-null">
          <img className="search-img" src="/pic/icon_empty.png" mode="scaleToFill" />
          <span className="prompt-tips">{errorTxt}</span>
        </div>
      )}
      {popupDialogShow && <PopupDialog record={record} onClose={() => setPopupDialogShow(false)} />}
    </div>
  );
}
