import ReactDOM from 'react-dom/client';

function ConfirmBox({ msg, onOk }) {
  return (
    <div className="confirm-box">
      <div className="content">
        <div className="text">{msg}</div>
        <div className="ok-btn" onClick={onOk}>
          确定
        </div>
      </div>
    </div>
  );
}

export async function showConfirm(msg) {
  return new Promise((resolve) => {
    const div = document.createElement('div');
    document.body.appendChild(div);
    const root = ReactDOM.createRoot(div);
    const onOk = () => {
      document.body.removeChild(div);
      resolve(true);
    };
    root.render(<ConfirmBox msg={msg} onOk={onOk} />);
  });
}

function ToastBox({ msg }) {
  return (
    <div className="toast-box">
      <div className="toast">{msg}</div>
    </div>
  );
}

export function showToast(msg) {
  const div = document.createElement('div');
  document.body.appendChild(div);
  const root = ReactDOM.createRoot(div);
  root.render(<ToastBox msg={msg} />);
  setTimeout(() => {
    root.unmount();
    document.body.removeChild(div);
  }, 3000);
}

export function parseLocationParams(params) {
  if (!params) return undefined;
  let real = params.startsWith('?') ? params.split('?')[1] : params;
  let _tmpList = real.split('&') || [];
  let _data = {};
  for (let _tmp of _tmpList) {
    let [_k, _v] = _tmp.split('=');
    _data[_k] = _v;
  }
  return _data;
}

export function parseLocationParamsAll(params) {
  if (!params) return {};
  let real = params.startsWith('?') ? params.split('?')[1] : params;
  let _tmpList = real.split('&') || [];
  let _data = {};
  for (let _tmp of _tmpList) {
    let [_k, _v] = _tmp.split('=');
    _data[_k] = _v;
  }
  return _data;
}

// 延迟执行
export function delay(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}
export async function copyToClipboard(text, onSuccess) {
  if (!text) {
    return showToast('内容为空,不可复制');
  }
  let div = document.createElement('div');
  document.body.appendChild(div);
  const root = ReactDOM.createRoot(div);
  root.render(
    <div className={`copy-to-clipboard`}>
      <textarea id="copyToClipboard" defaultValue={text} className="text-area"></textarea>
    </div>
  );
  await delay(200);
  let ta = document.getElementById('copyToClipboard');
  if (ta) {
    ta.select();
    let res = document.execCommand('copy');
    if (res) {
      if (onSuccess) {
        onSuccess();
      } else {
        showToast('复制成功');
      }
    }
  }
  await delay(500);
  root.unmount();
  document.body.removeChild(div);
}
